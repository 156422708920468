import React from "react";

import "./LoadingIcon.scss";

export default function LoadingIcon() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="LoadingIcon"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.9139375476009138s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(27.692307692307693 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.8377760853008377s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(55.38461538461539 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.7616146230007615s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(83.07692307692308 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.6854531607006854s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(110.76923076923077 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.6092916984006093s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(138.46153846153845 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.5331302361005331s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(166.15384615384616 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.4569687738004569s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(193.84615384615384 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.3808073115003808s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(221.53846153846155 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.30464584920030463s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(249.23076923076923 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.22848438690022846s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(276.9230769230769 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.15232292460015232s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(304.61538461538464 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="-0.07616146230007616s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(332.3076923076923 50 50)">
          <rect
            x="48"
            y="24"
            rx="0"
            ry="0"
            width="4"
            height="12"
            fill="#2e6edf"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9900990099009901s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </div>
  );
}
