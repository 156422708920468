import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Link, useSearchParams } from "react-router-dom";

import "./ApplyButton.scss";

export default function ApplyButton({ applyUrl }) {
  const { user } = useAuth();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  if (!user) {
    return (
      <Link
        to={`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`}
        className="Button ApplyButton"
      >
        Login to apply
      </Link>
    );
  } else if (user && !user.isSubscribed) {
    return (
      <Link
        to={`/payment${returnUrl ? "?returnUrl=" + returnUrl : ""}`}
        className="Button ApplyButton"
      >
        Subscribe to apply
      </Link>
    );
  } else if (user && user.isSubscribed)
    return (
      <a
        href={applyUrl}
        target="_blank"
        className="Button ApplyButton"
        rel="noreferrer"
      >
        Apply for this role
      </a>
    );
}
