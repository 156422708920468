import React from "react";
import { Link } from "react-router-dom";

import "./Tags.scss";
import Loading from "../Loading/Loading";

export default function Tags({ tags, forFoodPage, foods }) {
  return (
    <div
      className="Tag"
      style={{ justifyContent: forFoodPage ? "start" : "center" }}
    >
      <div className="Tag-container">
        {foods.length === 0 ? (
          <Loading />
        ) : (
          <h3 className="Tag-header">Trending categories</h3>
        )}
        <div className="Tag-items">
          {tags.map((tag) => (
            <Link key={tag.name} to={`/tag/${tag.name}`} className="Tag-item">
              {tag.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

//{!forFoodPage && `{${tag.count}}`}
