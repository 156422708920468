import { useState, createContext, useContext } from "react";
import * as userService from "../services/userService";
import { toast } from "react-toastify";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(userService.getUser());

  // USING THE LOGIN FUNCTION
  const login = async (email, password) => {
    try {
      const user = await userService.login(email, password);
      setUser(user);
      toast.success("Login successfull!");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  // USING THE REGISTER FUNCTION
  const register = async (data) => {
    try {
      const user = await userService.register(data);
      setUser(user);
      toast.success("Account created successfully!");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  // USING THE LOGOUT FUNCTION
  const logout = () => {
    userService.logout();
    setUser(null);
    toast.success("Logout successfull!");
  };

  const paymentLogout = () => {
    userService.logout();
    setUser(null);
  };

  const unSubscribedLogout2 = () => {
    setTimeout(unSubscribedLogout, 1296000000);
  };

  const unSubscribedLogout = () => {
    userService.logout();
    setUser(null);
    toast.warning("You have been unsubscribed, please login to subscribe!");
  };

  // USING THE UPDATE PROFILE FUNCTION
  const updateProfile = async (user) => {
    const updatedUser = await userService.updateProfile(user);
    toast.success("Profile update was successfull!");
    if (updatedUser) setUser(updatedUser);
  };

  /**const unSubscribe = async (user) => {
    const unSubscribe = await userService.unSubscribe(user);
    toast.warning("You have been unsubscribed");
    if (unSubscribe) setUser(unSubscribe);
  };**/

  const subscribe = async (user) => {
    const subscribe = await userService.subscribe(user);
    toast.success("Subscribed successfully, please login again!");
    if (subscribe) setUser(subscribe);
    setTimeout(unSubscribedLogout2, 1296000000);
    paymentLogout();
  };

  //USING THE CHANGE PASSWORD FUNCTION
  const changePassword = async (passwords) => {
    try {
      await userService.changePassword(passwords);
      logout();
      toast.success("Password changed successfully, please login again!");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        updateProfile,
        changePassword,
        subscribe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
