import React from "react";
import { Link } from "react-router-dom";

import "./Thumbnails.scss";
import LocationIcon from "../LocationIcon/LocationIcon";
import DurationIcon from "../DurationIcon/DurationIcon";

export default function Thumbnails({ foods }) {
  const reversedRoles = foods.slice(0).reverse();
  return (
    <ul>
      {reversedRoles.map((food) => (
        <li key={food.id} className="Thumbnails">
          <Link to={`/role/${food.id}`} className="Thumbnails-content">
            <h3>{food.name}</h3>
            <div className="Thumbnails-locadur">
              <div>
                <span>
                  <LocationIcon />
                </span>
                {food.location}
              </div>
              <div>
                <span>
                  <DurationIcon />
                </span>
                {food.duration}
              </div>
            </div>
            <div>
              <div className="Thumbnails-tags">
                {food.tags
                  .map((tag) => ({ name: tag }))
                  .map((tag) => (
                    <div key={tag.name} className="Thumbnails-tag">
                      {tag.name}
                    </div>
                  ))}
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
