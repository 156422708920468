import React from "react";
import { useLoading } from "../../hooks/useLoading";

import "./Loading.scss";
import LoadingIcon from "../LoadingIcon/LoadingIcon";

export default function Loading() {
  const { isLoading } = useLoading();
  if (!isLoading) return;

  return (
    <div className="LoadingContainer">
      <div className="LoadingContainer-items">
        <LoadingIcon />
        <div className="LoadingContainer-loadingText">Loading...</div>
      </div>
    </div>
  );
}
