import React from "react";
import { PaystackButton } from "react-paystack";
import { useAuth } from "../../hooks/useAuth";

import "./ButtonPaystack.scss";

export default function ButtonPaystack() {
  const { subscribe, user } = useAuth();

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY;
  const amount = 100000; // Remember, set in kobo!
  const email = user.email;

  const componentProps = {
    email,
    amount,
    publicKey,
    text: "Pay with Paystack",
    onSuccess: () => subscribe(user),
  };

  return <PaystackButton className="Paystack-button" {...componentProps} />;
}
