import React, { createContext, useContext, useEffect, useState } from "react";

const ViewportContext = createContext();

export const ViewportProvider = ({ children }) => {
  const [viewport, setViewport] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setViewport(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ viewport }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const { viewport } = useContext(ViewportContext);
  return { viewport };
};
