import axios from "axios";

export const getUser = () =>
  localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

// LOGIN FUNCTION
export const login = async (email, password) => {
  const { data } = await axios.post("api/users/login", { email, password });
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

//REGISTER FUNCTION
export const register = async (registerData) => {
  const { data } = await axios.post("api/users/register", registerData);
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

// LOGOUT FUNCTION
export const logout = () => {
  localStorage.removeItem("user");
};

// UPDATE PROFILE FUNCTION
export const updateProfile = async (user) => {
  const { data } = await axios.put("/api/users/updateProfile", user);
  localStorage.setItem("user", JSON.stringify(data));
  return data;
};

//UPDATE SUBSCRIBED
export const subscribe = async (user) => {
  await axios.put("/api/users/subscribe", user);
};

//UPDATE UNSUBSCRIBED
export const unSubscribe = async (user) => {
  await axios.put("/api/users/unsubscribe", user);
};

// USING THE PCHANGE PASSWORD FUNCTION
export const changePassword = async (passwords) => {
  await axios.put("/api/users/changePassword", passwords);
};
