import React from "react";

import "./Title.scss";

export default function Title({ title, fontSize, margin, className }) {
  return (
    <h1 style={{ fontSize, margin }} className={className}>
      {title}
    </h1>
  );
}
