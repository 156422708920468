import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

import "./LoginPage.scss";

export default function LoginPage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { user, login } = useAuth();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  useEffect(() => {
    if (!user) return;

    returnUrl ? navigate(returnUrl) : navigate("/");
  }, [user, navigate, returnUrl]);

  const submit = async ({ email, password }) => {
    await login(email, password);
  };

  return (
    <div className="LoginPage">
      <div className="LoginPage-content">
        <Title title="Login" className="LoginPage-title" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            type="email"
            placeholder="Email"
            className="LoginPage-input"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: "Enter a valid email address",
              },
            })}
            error={errors.email}
          />

          <Input
            type="password"
            placeholder="Password"
            className="LoginPage-input"
            {...register("password", {
              required: true,
            })}
            error={errors.password}
          />

          <Button
            type="submit"
            text="Login to your account"
            className="LoginPage-button"
          />

          <div className="LoginPage-signupRedirect">
            New to kachislist? &nbsp;
            <Link
              to={`/register${returnUrl ? "?returnUrl=" + returnUrl : ""}`}
              className="LoginPage-signupLink"
            >
              Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
