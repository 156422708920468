import React from "react";

import "./ContactUsPage.scss";
import Title from "../../components/Title/Title";

export default function ContactUsPage() {
  return (
    <div className="ContactUs">
      <Title title="We reply in minutes!" className="ContactUs-title" />
      <div className="ContactUs-content">
        <div className="ContactUs-text">
          Have any questions, suggestions or perhaps, any role(s) you'd like us
          to add? We'd love to hear from you!
        </div>
        <div className="ContactUs-details">
          <div>
            Email:
            <span>
              {" "}
              <a
                href="mailto:kachislist@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="ContactUs-links"
              >
                kachislist@gmail.com
              </a>
            </span>
          </div>
          <div>
            WhatsApp:
            <span>
              {" "}
              <a
                className="ContactUs-links"
                href="https://wa.link/9sy70j"
                target="_blank"
                rel="noreferrer"
              >
                0803 358 1100
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
