import React from "react";
import { Link } from "react-router-dom";

import "./NotFound.scss";

export default function NotFound({ message, linkRoute, linkText }) {
  return (
    <div className="NotFound">
      <h3>{message}</h3>
      <Link to={linkRoute} className="NotFound-linkToHome Button">
        {linkText}
      </Link>
    </div>
  );
}

NotFound.defaultProps = {
  message: "Nothing Found!",
  linkRoute: "/",
  linkText: "View all roles",
};
