import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../hooks/useCart";
import { toast } from "react-toastify";
import NotFound from "../../components/NotFound/NotFound";

import "./CartPage.scss";
import TrashIcon from "../../components/TrashIcon/TrashIcon";
import Title from "../../components/Title/Title";
import LocationIcon from "../../components/LocationIcon/LocationIcon";
import DurationIcon from "../../components/DurationIcon/DurationIcon";

export default function CartPage() {
  const { cart, removeFromCart } = useCart();
  return (
    <>
      {cart.items.length === 0 ? (
        <div className="SavedRoles-empty">
          <NotFound message="Your saved roles is empty!" />
        </div>
      ) : (
        <div>
          <Title title="Saved roles" className="SavedRoles-title" />
          <ul>
            {cart.items.map((item) => (
              <li key={item.food.id} className="SavedRoles">
                <Link
                  to={`/role/${item.food.id}`}
                  className="SavedRoles-content"
                >
                  <h3>{item.food.name}</h3>
                  <div className="SavedRoles-locadur">
                    <div>
                      <LocationIcon />
                      <div>{item.food.location}</div>
                    </div>
                    <div>
                      <DurationIcon />
                      <div>{item.food.duration}</div>
                    </div>
                  </div>
                  <div>
                    <div className="SavedRoles-tags">
                      {item.food.tags
                        .map((tag) => ({ name: tag }))
                        .map((tag) => (
                          <div key={tag.name} className="SavedRoles-tag">
                            {tag.name}
                          </div>
                        ))}
                    </div>
                  </div>
                </Link>
                <div
                  onClick={() => {
                    removeFromCart(item.food.id);
                    toast.success("Role removed successfully!");
                  }}
                  className="SavedRoles-trashIcon"
                >
                  <TrashIcon />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
