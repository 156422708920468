import React from "react";
import ButtonPaystack from "../../components/ButtonPaystack/ButtonPaystack";

import "./PaymentPage.scss";
import CheckIcon from "../../components/CheckIcon/CheckIcon";

export default function PaymentPage() {
  return (
    <div className="PaymentPage">
      <div className="PaymentPage-content">
        <div className="PaymentPage-thumbnail">
          <h3>Flat price</h3>
          <div className="PaymentPage-value">
            <div className="PaymentPage-currency">$</div>
            <div className="PaymentPage-amount">1</div>
            <div className="PaymentPage-secondAmount">/ month</div>
          </div>
          <div className="PaymentPage-text">
            <div>
              <CheckIcon />
            </div>
            <div>Have access and apply to ALL roles.</div>
          </div>
          <div className="PaymentPage-details">
            <ButtonPaystack />
          </div>
        </div>
      </div>
    </div>
  );
}
