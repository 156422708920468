import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Input from "../../components/Input/Input";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

export default function RegisterPage() {
  const auth = useAuth();
  const { user } = auth;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const returnUrl = params.get("returnUrl");

  useEffect(
    () => {
      if (!user) return;
      returnUrl ? navigate(returnUrl) : navigate("/");
    },
    [user],
    navigate,
    returnUrl
  );

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    await auth.register(data);
  };

  return (
    <div className="RegisterPage">
      <div className="RegisterPage-content">
        <Title title="Create your account" className="RegisterPage-title" />
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Input
            type="text"
            placeholder="First Name"
            className="RegisterPage-input"
            {...register("firstname", {
              required: true,
            })}
            error={errors.firstname}
          />

          <Input
            type="text"
            placeholder="Last Name"
            className="RegisterPage-input"
            {...register("lastname", {
              required: true,
            })}
            error={errors.lastname}
          />

          <Input
            type="tel"
            placeholder="Phone Number"
            className="RegisterPage-input"
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9]{11}$/,
                message: "Enter a valid phone number",
              },
            })}
            error={errors.phone}
          />

          <Input
            type="email"
            placeholder="Email"
            className="RegisterPage-input"
            {...register("email", {
              required: true,
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/i,
                message: "Enter a valid email address",
              },
            })}
            error={errors.email}
          />

          <Input
            type="password"
            placeholder="Password"
            className="RegisterPage-input"
            {...register("password", {
              required: true,
              minLength: 5,
            })}
            error={errors.password}
          />

          <Input
            type="password"
            placeholder="Confirm Password"
            className="RegisterPage-input"
            {...register("confirmPassword", {
              required: true,
              validate: (value) =>
                value !== getValues("password")
                  ? "Passwords do no match"
                  : true,
            })}
            error={errors.confirmPassword}
          />

          <Button
            type="submit"
            text="Create my account"
            className="RegisterPage-button"
          />

          <div className="RegisterPage-signupRedirect">
            Already a user? &nbsp;
            <Link to={`/login${returnUrl ? "?returnUrl=" + returnUrl : ""}`}>
              Login here
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
