import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useCart } from "../../hooks/useCart";
import { useAuth } from "../../hooks/useAuth";
import { useViewport } from "../../hooks/useViewport";
import { WIDTH_BREAKPOINT } from "../../constants/breakpoint";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import CloseIcon from "../CloseIcon/CloseIcon";

import "./Header.scss";
import AccountIcon from "../AccountIcon/AccountIcon";
import Search from "../Search/Search";
import SearchIcon from "../SearchIcon/SearchIcon";

export default function Header() {
  // ADD <Link to="/orders">Orders</Link> JUST AFTER PROFILE IF YOU NEED ORDERS LINK
  const { user, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    closeSearch();
  };

  const toggleSearchOpen = () => {
    setSearchOpen(!searchOpen);
    closeMenu();
  };

  const closeSearch = () => {
    setSearchOpen(false);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const { cart } = useCart();

  const { viewport } = useViewport();
  const isMobile = viewport < WIDTH_BREAKPOINT;

  const UserDesktop = () => {
    return user && !isMobile ? (
      <div className="Header-userDesktop">
        <Search closeSearch={closeSearch} />
        <ul className="Header-leftDetails">
          <li className="Header-detailsItem">
            <Link to="/savedroles">
              Saved roles
              {cart.totalCount > 0 && (
                <span className="Header-favCount">{cart.totalCount}</span>
              )}
            </Link>
          </li>
          {/**<li className="Header-detailsItem">
            <Link to="/payment">Subscribe</Link>
              </li>**/}
          <li className="Header-detailsItem">
            <Link to="/contact" className="Contact">
              Contact us
            </Link>
          </li>
        </ul>
        <ul className="Header-rightDetails">
          <li className="Header-detailsItem">
            <Link to="/profile" className="Header-profile">
              <AccountIcon />
              <span>{user.firstname}</span>
            </Link>
          </li>
          <li className="Header-detailsItem">
            <a onClick={logout}>Logout</a>
          </li>
        </ul>
      </div>
    ) : null;
  };

  const GuestDesktop = () => {
    return !user && !isMobile ? (
      <div className="Header-guestDesktop">
        <Search closeSearch={closeSearch} />
        <ul className="Header-leftDetails">
          <li className="Header-detailsItem">
            <Link to="/savedroles">
              Saved roles
              {cart.totalCount > 0 && (
                <span className="Header-favCount">{cart.totalCount}</span>
              )}
            </Link>
          </li>
          {/**<li className="Header-detailsItem">
            <Link to="/payment">Subscribe</Link>
              </li>**/}
          <li className="Header-detailsItem">
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
        <ul className="Header-rightDetails">
          <li className="Header-detailsItem">
            <Link to="/register" className="Button">
              Create account
            </Link>
          </li>
          <li className="Header-detailsItem">
            <Link to="/login">Login</Link>
          </li>
        </ul>
      </div>
    ) : null;
  };

  const UserMobile = () => {
    return user && isMobile ? (
      <div>
        <div className="Header-mobileIcons">
          <div onClick={toggleSearchOpen} className="Header-searchCancelIcon">
            {!searchOpen ? <SearchIcon /> : <CloseIcon />}
          </div>
          <div onClick={toggleMenuOpen}>
            {!menuOpen ? <HamburgerIcon /> : <CloseIcon />}
          </div>
        </div>
        {searchOpen && (
          <div className="Header-searchSection">
            <Search closeSearch={closeSearch} />
          </div>
        )}
        {menuOpen && (
          <div className="Header-userMobile">
            <ul>
              <li className="Header-userMobile-item">
                <Link
                  to="/profile"
                  className="Header-profile"
                  onClick={closeMenu}
                >
                  <AccountIcon />
                  <span>{user.firstname}</span>
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link to="/savedroles" onClick={closeMenu}>
                  Saved roles
                  {cart.totalCount > 0 && (
                    <span className="Header-favCount">{cart.totalCount}</span>
                  )}
                </Link>
              </li>
              {/**<li className="Header-userMobile-item">
                <Link to="/payment" onClick={closeMenu}>
                  Subscribe
                </Link>
                  </li>**/}
              <li className="Header-userMobile-item">
                <Link to="/contact" onClick={closeMenu}>
                  Contact us
                </Link>
              </li>
              <li
                onClick={closeMenu}
                className="Header-userMobile-item Header-mobileLogout"
              >
                <a onClick={logout}>Logout</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  const GuestMobile = () => {
    return !user && isMobile ? (
      <div>
        <div className="Header-mobileIcons">
          <div onClick={toggleSearchOpen} className="Header-searchCancelIcon">
            {!searchOpen ? <SearchIcon /> : <CloseIcon />}
          </div>
          <div onClick={toggleMenuOpen}>
            {!menuOpen ? <HamburgerIcon /> : <CloseIcon />}
          </div>
        </div>
        {searchOpen && (
          <div className="Header-searchSection">
            <Search closeSearch={closeSearch} />
          </div>
        )}
        {menuOpen && (
          <div className="Header-guestMobile">
            <ul>
              <li className="Header-userMobile-item">
                <Link to="/savedroles" onClick={closeMenu}>
                  Saved roles
                  {cart.totalCount > 0 && (
                    <span className="Header-favCount">{cart.totalCount}</span>
                  )}
                </Link>
              </li>
              {/**<li className="Header-userMobile-item">
                <Link to="/payment" onClick={closeMenu}>
                  Subscribe
                </Link>
                  </li>**/}
              <li className="Header-userMobile-item">
                <Link to="/contact" onClick={closeMenu}>
                  Contact us
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link
                  to="/login"
                  onClick={closeMenu}
                  className="Header-userMobile-login"
                >
                  Login
                </Link>
              </li>
              <li className="Header-userMobile-item">
                <Link
                  to="/register"
                  onClick={closeMenu}
                  className="Button"
                  style={{
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  Create account
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <header className="Header">
      <div className="Header-announcement">Find remote roles you want!</div>
      <ContentWrapper className="Header-contentWrapper">
        <div className="Header-details">
          <Link
            to="/"
            onClick={() => {
              closeMenu();
              closeSearch();
            }}
          >
            <div className="Header-logo">kachislist</div>
          </Link>
          <div className="Header-content">
            {UserDesktop()}
            {GuestDesktop()}
            {UserMobile()}
            {GuestMobile()}
          </div>
        </div>
      </ContentWrapper>
    </header>
  );
}
