import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getById } from "../../services/foodService";
import { Link } from "react-router-dom";

import "./FoodPage.scss";
import { useCart } from "../../hooks/useCart";
import NotFound from "../../components/NotFound/NotFound";
import ApplyButton from "../../components/ApplyButton/ApplyButton";

export default function FoodPage() {
  const [food, setFood] = useState({});
  const { id } = useParams();
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    addToCart(food);
  };

  useEffect(() => {
    getById(id).then(setFood);
  }, [id]);
  return (
    <>
      {!food ? (
        <NotFound message="Role Not Found" linkText="Back To Homepage" />
      ) : (
        <div className="FoodPage">
          <div className="FoodPage-title">
            <h1>{food.name}</h1>
            <Link onClick={handleAddToCart} className="Button">
              Save
            </Link>
          </div>
          <div>
            <div>
              <h3>{food.heading1}</h3>
              <ul>
                <li className="FoodPage-list">{food.list1a}</li>
                <li className="FoodPage-list">{food.list1b}</li>
                <li className="FoodPage-list">{food.list1c}</li>
                <li className="FoodPage-list">{food.list1d}</li>
                <li className="FoodPage-list">{food.list1e}</li>
                <li className="FoodPage-list">{food.list1f}</li>
                <li className="FoodPage-list">{food.list1g}</li>
                <li className="FoodPage-list">{food.list1h}</li>
                <li className="FoodPage-list">{food.list1i}</li>
                <li className="FoodPage-list">{food.list1j}</li>
                <li className="FoodPage-list">{food.list1k}</li>
                <li className="FoodPage-list">{food.list1l}</li>
                <li className="FoodPage-list">{food.list1m}</li>
                <li className="FoodPage-list">{food.list1n}</li>
                <li className="FoodPage-list">{food.list1o}</li>
                <li className="FoodPage-list">{food.list1p}</li>
                <li className="FoodPage-list">{food.list1q}</li>
                <li className="FoodPage-list">{food.list1r}</li>
                <li className="FoodPage-list">{food.list1s}</li>
                <li className="FoodPage-list">{food.list1t}</li>
                <li className="FoodPage-list">{food.list1u}</li>
                <li className="FoodPage-list">{food.list1v}</li>
                <li className="FoodPage-list">{food.list1w}</li>
                <li className="FoodPage-list">{food.list1x}</li>
                <li className="FoodPage-list">{food.list1y}</li>
                <li className="FoodPage-list">{food.list1z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading2}</h3>
              <ul>
                <li className="FoodPage-list">{food.list2a}</li>
                <li className="FoodPage-list">{food.list2b}</li>
                <li className="FoodPage-list">{food.list2c}</li>
                <li className="FoodPage-list">{food.list2d}</li>
                <li className="FoodPage-list">{food.list2e}</li>
                <li className="FoodPage-list">{food.list2f}</li>
                <li className="FoodPage-list">{food.list2g}</li>
                <li className="FoodPage-list">{food.list2h}</li>
                <li className="FoodPage-list">{food.list2i}</li>
                <li className="FoodPage-list">{food.list2j}</li>
                <li className="FoodPage-list">{food.list2k}</li>
                <li className="FoodPage-list">{food.list2l}</li>
                <li className="FoodPage-list">{food.list2m}</li>
                <li className="FoodPage-list">{food.list2n}</li>
                <li className="FoodPage-list">{food.list2o}</li>
                <li className="FoodPage-list">{food.list2p}</li>
                <li className="FoodPage-list">{food.list2q}</li>
                <li className="FoodPage-list">{food.list2r}</li>
                <li className="FoodPage-list">{food.list2s}</li>
                <li className="FoodPage-list">{food.list2t}</li>
                <li className="FoodPage-list">{food.list2u}</li>
                <li className="FoodPage-list">{food.list2v}</li>
                <li className="FoodPage-list">{food.list2w}</li>
                <li className="FoodPage-list">{food.list2x}</li>
                <li className="FoodPage-list">{food.list2y}</li>
                <li className="FoodPage-list">{food.list2z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading3}</h3>
              <ul>
                <li className="FoodPage-list">{food.list3a}</li>
                <li className="FoodPage-list">{food.list3b}</li>
                <li className="FoodPage-list">{food.list3c}</li>
                <li className="FoodPage-list">{food.list3d}</li>
                <li className="FoodPage-list">{food.list3e}</li>
                <li className="FoodPage-list">{food.list3f}</li>
                <li className="FoodPage-list">{food.list3g}</li>
                <li className="FoodPage-list">{food.list3h}</li>
                <li className="FoodPage-list">{food.list3i}</li>
                <li className="FoodPage-list">{food.list3j}</li>
                <li className="FoodPage-list">{food.list3k}</li>
                <li className="FoodPage-list">{food.list3l}</li>
                <li className="FoodPage-list">{food.list3m}</li>
                <li className="FoodPage-list">{food.list3n}</li>
                <li className="FoodPage-list">{food.list3o}</li>
                <li className="FoodPage-list">{food.list3p}</li>
                <li className="FoodPage-list">{food.list3q}</li>
                <li className="FoodPage-list">{food.list3r}</li>
                <li className="FoodPage-list">{food.list3s}</li>
                <li className="FoodPage-list">{food.list3t}</li>
                <li className="FoodPage-list">{food.list3u}</li>
                <li className="FoodPage-list">{food.list3v}</li>
                <li className="FoodPage-list">{food.list3w}</li>
                <li className="FoodPage-list">{food.list3x}</li>
                <li className="FoodPage-list">{food.list3y}</li>
                <li className="FoodPage-list">{food.list3z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading4}</h3>
              <ul>
                <li className="FoodPage-list">{food.list4a}</li>
                <li className="FoodPage-list">{food.list4b}</li>
                <li className="FoodPage-list">{food.list4c}</li>
                <li className="FoodPage-list">{food.list4d}</li>
                <li className="FoodPage-list">{food.list4e}</li>
                <li className="FoodPage-list">{food.list4f}</li>
                <li className="FoodPage-list">{food.list4g}</li>
                <li className="FoodPage-list">{food.list4h}</li>
                <li className="FoodPage-list">{food.list4i}</li>
                <li className="FoodPage-list">{food.list4j}</li>
                <li className="FoodPage-list">{food.list4k}</li>
                <li className="FoodPage-list">{food.list4l}</li>
                <li className="FoodPage-list">{food.list4m}</li>
                <li className="FoodPage-list">{food.list4n}</li>
                <li className="FoodPage-list">{food.list4o}</li>
                <li className="FoodPage-list">{food.list4p}</li>
                <li className="FoodPage-list">{food.list4q}</li>
                <li className="FoodPage-list">{food.list4r}</li>
                <li className="FoodPage-list">{food.list4s}</li>
                <li className="FoodPage-list">{food.list4t}</li>
                <li className="FoodPage-list">{food.list4u}</li>
                <li className="FoodPage-list">{food.list4v}</li>
                <li className="FoodPage-list">{food.list4w}</li>
                <li className="FoodPage-list">{food.list4x}</li>
                <li className="FoodPage-list">{food.list4y}</li>
                <li className="FoodPage-list">{food.list4z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading5}</h3>
              <ul>
                <li className="FoodPage-list">{food.list5a}</li>
                <li className="FoodPage-list">{food.list5b}</li>
                <li className="FoodPage-list">{food.list5c}</li>
                <li className="FoodPage-list">{food.list5d}</li>
                <li className="FoodPage-list">{food.list5e}</li>
                <li className="FoodPage-list">{food.list5f}</li>
                <li className="FoodPage-list">{food.list5g}</li>
                <li className="FoodPage-list">{food.list5h}</li>
                <li className="FoodPage-list">{food.list5i}</li>
                <li className="FoodPage-list">{food.list5j}</li>
                <li className="FoodPage-list">{food.list5k}</li>
                <li className="FoodPage-list">{food.list5l}</li>
                <li className="FoodPage-list">{food.list5m}</li>
                <li className="FoodPage-list">{food.list5n}</li>
                <li className="FoodPage-list">{food.list5o}</li>
                <li className="FoodPage-list">{food.list5p}</li>
                <li className="FoodPage-list">{food.list5q}</li>
                <li className="FoodPage-list">{food.list5r}</li>
                <li className="FoodPage-list">{food.list5s}</li>
                <li className="FoodPage-list">{food.list5t}</li>
                <li className="FoodPage-list">{food.list5u}</li>
                <li className="FoodPage-list">{food.list5v}</li>
                <li className="FoodPage-list">{food.list5w}</li>
                <li className="FoodPage-list">{food.list5x}</li>
                <li className="FoodPage-list">{food.list5y}</li>
                <li className="FoodPage-list">{food.list5z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading6}</h3>
              <ul>
                <li className="FoodPage-list">{food.list6a}</li>
                <li className="FoodPage-list">{food.list6b}</li>
                <li className="FoodPage-list">{food.list6c}</li>
                <li className="FoodPage-list">{food.list6d}</li>
                <li className="FoodPage-list">{food.list6e}</li>
                <li className="FoodPage-list">{food.list6f}</li>
                <li className="FoodPage-list">{food.list6g}</li>
                <li className="FoodPage-list">{food.list6h}</li>
                <li className="FoodPage-list">{food.list6i}</li>
                <li className="FoodPage-list">{food.list6j}</li>
                <li className="FoodPage-list">{food.list6k}</li>
                <li className="FoodPage-list">{food.list6l}</li>
                <li className="FoodPage-list">{food.list6m}</li>
                <li className="FoodPage-list">{food.list6n}</li>
                <li className="FoodPage-list">{food.list6o}</li>
                <li className="FoodPage-list">{food.list6p}</li>
                <li className="FoodPage-list">{food.list6q}</li>
                <li className="FoodPage-list">{food.list6r}</li>
                <li className="FoodPage-list">{food.list6s}</li>
                <li className="FoodPage-list">{food.list6t}</li>
                <li className="FoodPage-list">{food.list6u}</li>
                <li className="FoodPage-list">{food.list6v}</li>
                <li className="FoodPage-list">{food.list6w}</li>
                <li className="FoodPage-list">{food.list6x}</li>
                <li className="FoodPage-list">{food.list6y}</li>
                <li className="FoodPage-list">{food.list6z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading7}</h3>
              <ul>
                <li className="FoodPage-list">{food.list7a}</li>
                <li className="FoodPage-list">{food.list7b}</li>
                <li className="FoodPage-list">{food.list7c}</li>
                <li className="FoodPage-list">{food.list7d}</li>
                <li className="FoodPage-list">{food.list7e}</li>
                <li className="FoodPage-list">{food.list7f}</li>
                <li className="FoodPage-list">{food.list7g}</li>
                <li className="FoodPage-list">{food.list7h}</li>
                <li className="FoodPage-list">{food.list7i}</li>
                <li className="FoodPage-list">{food.list7j}</li>
                <li className="FoodPage-list">{food.list7k}</li>
                <li className="FoodPage-list">{food.list7l}</li>
                <li className="FoodPage-list">{food.list7m}</li>
                <li className="FoodPage-list">{food.list7n}</li>
                <li className="FoodPage-list">{food.list7o}</li>
                <li className="FoodPage-list">{food.list7p}</li>
                <li className="FoodPage-list">{food.list7q}</li>
                <li className="FoodPage-list">{food.list7r}</li>
                <li className="FoodPage-list">{food.list7s}</li>
                <li className="FoodPage-list">{food.list7t}</li>
                <li className="FoodPage-list">{food.list7u}</li>
                <li className="FoodPage-list">{food.list7v}</li>
                <li className="FoodPage-list">{food.list7w}</li>
                <li className="FoodPage-list">{food.list7x}</li>
                <li className="FoodPage-list">{food.list7y}</li>
                <li className="FoodPage-list">{food.list7z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading8}</h3>
              <ul>
                <li className="FoodPage-list">{food.list8a}</li>
                <li className="FoodPage-list">{food.list8b}</li>
                <li className="FoodPage-list">{food.list8c}</li>
                <li className="FoodPage-list">{food.list8d}</li>
                <li className="FoodPage-list">{food.list8e}</li>
                <li className="FoodPage-list">{food.list8f}</li>
                <li className="FoodPage-list">{food.list8g}</li>
                <li className="FoodPage-list">{food.list8h}</li>
                <li className="FoodPage-list">{food.list8i}</li>
                <li className="FoodPage-list">{food.list8j}</li>
                <li className="FoodPage-list">{food.list8k}</li>
                <li className="FoodPage-list">{food.list8l}</li>
                <li className="FoodPage-list">{food.list8m}</li>
                <li className="FoodPage-list">{food.list8n}</li>
                <li className="FoodPage-list">{food.list8o}</li>
                <li className="FoodPage-list">{food.list8p}</li>
                <li className="FoodPage-list">{food.list8q}</li>
                <li className="FoodPage-list">{food.list8r}</li>
                <li className="FoodPage-list">{food.list8s}</li>
                <li className="FoodPage-list">{food.list8t}</li>
                <li className="FoodPage-list">{food.list8u}</li>
                <li className="FoodPage-list">{food.list8v}</li>
                <li className="FoodPage-list">{food.list8w}</li>
                <li className="FoodPage-list">{food.list8x}</li>
                <li className="FoodPage-list">{food.list8y}</li>
                <li className="FoodPage-list">{food.list8z}</li>
              </ul>
            </div>
            <div>
              <h3>{food.heading9}</h3>
              <ul>
                <li className="FoodPage-list">{food.list9a}</li>
                <li className="FoodPage-list">{food.list9b}</li>
                <li className="FoodPage-list">{food.list9c}</li>
                <li className="FoodPage-list">{food.list9d}</li>
                <li className="FoodPage-list">{food.list9e}</li>
                <li className="FoodPage-list">{food.list9f}</li>
                <li className="FoodPage-list">{food.list9g}</li>
                <li className="FoodPage-list">{food.list9h}</li>
                <li className="FoodPage-list">{food.list9i}</li>
                <li className="FoodPage-list">{food.list9j}</li>
                <li className="FoodPage-list">{food.list9k}</li>
                <li className="FoodPage-list">{food.list9l}</li>
                <li className="FoodPage-list">{food.list9m}</li>
                <li className="FoodPage-list">{food.list9n}</li>
                <li className="FoodPage-list">{food.list9o}</li>
                <li className="FoodPage-list">{food.list9p}</li>
                <li className="FoodPage-list">{food.list9q}</li>
                <li className="FoodPage-list">{food.list9r}</li>
                <li className="FoodPage-list">{food.list9s}</li>
                <li className="FoodPage-list">{food.list9t}</li>
                <li className="FoodPage-list">{food.list9u}</li>
                <li className="FoodPage-list">{food.list9v}</li>
                <li className="FoodPage-list">{food.list9w}</li>
                <li className="FoodPage-list">{food.list9x}</li>
                <li className="FoodPage-list">{food.list9y}</li>
                <li className="FoodPage-list">{food.list9z}</li>
              </ul>
            </div>
          </div>
          <div className="FoodPage-applyButton">
            <ApplyButton applyUrl={food.applyUrl} />
          </div>
        </div>
      )}
    </>
  );
}
