import "./InputContainer.scss";

export default function InputContainer({ label, bgColor, children }) {
  return (
    <div style={{ backgroundColor: bgColor }} className="InputContainer">
      <label>{label}</label>
      <div>{children}</div>
    </div>
  );
}
