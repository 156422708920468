import React from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

export default function ProfilePage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { user, updateProfile } = useAuth();

  const submit = (user) => {
    updateProfile(user);
  };

  return (
    <div className="ProfilePage">
      <div className="ProfilePage-content">
        <Title title="Update Profile" className="ProfilePage-title" />
        <form onSubmit={handleSubmit(submit)}>
          <Input
            defaultValue={user.firstname}
            type="text"
            label="First Name"
            className="ProfilePage-input"
            {...register("firstname", {
              required: true,
            })}
            error={errors.firstname}
          />
          <Input
            defaultValue={user.lastname}
            type="text"
            label="Last Name"
            className="ProfilePage-input"
            {...register("lastname", {
              required: true,
            })}
            error={errors.lastname}
          />
          <Input
            defaultValue={user.phone}
            type="tel"
            label="Phone Number"
            className="ProfilePage-input"
            {...register("phone", {
              required: true,
              pattern: {
                value: /^[0-9]{11}$/,
                message: "Enter a valid phone number",
              },
            })}
            error={errors.phone}
          />

          <Button type="submit" text="Update" className="ProfilePage-button" />
        </form>

        <ChangePassword />
      </div>
    </div>
  );
}
